import { Badge, Box, Flex, Heading, Text } from '@hometime-ui/core'
import { graphql } from 'gatsby'
import FormattedHTML from '~/components/FormattedHTML'
import Image from '~/components/Image'
import PageWrapper from '~/components/PageWrapper'
import React from 'react'
import SEO from '~/components/SEO'
import SocialMediaShareLinks from '~/components/SocialMediaShareLinks'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from '~/components/WidthWrapper'

const BlogPost = ({ location, data }) => {
  const screen = useScreen()

  const {
    title,
    intro,
    metaTitle,
    metaDescription,
    metaKeywords,
    metaImage,
    bannerImage,
    articleBody,
    publishDate,
    slug,
    tags,
  } = data.contentfulArticle

  const { siteUrl } = data.site.siteMetadata

  const url = siteUrl ? `${siteUrl}/blog/${slug}` : null

  return (
    <PageWrapper path={location.pathName}>
      <SEO
        pathname={location.pathname}
        title={metaTitle || title}
        description={metaDescription || intro.childMarkdownRemark.excerpt}
        image={metaImage && metaImage.fluid.src}
        keywords={metaKeywords && metaKeywords}
      />
      <Box bg="white">
        <WidthWrapper>
          <Box py={{ base: 8, lg: 12 }}>
            <Box
              h={{ base: '300px', lg: '400px' }}
              pos="relative"
              overflow="hidden"
              borderRadius="lg"
            >
              <Image w="100%" src={bannerImage.fluid} />
            </Box>
            <Box maxW="740px" mx="auto" mt={{ base: 6, lg: 12 }}>
              <Heading level={screen.isMobile ? 2 : 1}>{title}</Heading>
              <Text color="gray.700" mt="4" fontSize={{ base: 'md', md: 'lg' }}>
                {metaDescription}
              </Text>
              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems={{ md: 'center' }}
                mt="4"
              >
                <Box>
                  {tags.map((tag, y) => {
                    return (
                      <Badge colorScheme="base" size="md" key={y} mr="2" mt="2">
                        {tag}
                      </Badge>
                    )
                  })}
                </Box>
                <Text
                  color="gray.700"
                  fontSize="xs"
                  mt={{ base: 4, md: 0 }}
                  ml={{ md: 'auto' }}
                >
                  Published on {publishDate}
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                bg="gray.200"
                p="4"
                borderRadius="lg"
                my={{ base: 4, md: 8 }}
              >
                <Text fontWeight="bold" color="gray.700">
                  Share this article
                </Text>
                <SocialMediaShareLinks title={title} url={url} />
              </Flex>
              <Box
                borderTop="1px solid #E2E4E7"
                borderBottom="1px solid #E2E4E7"
                mt={{ base: 4, md: 8 }}
                py={{ base: 4, md: 8 }}
              >
                <FormattedHTML
                  className="blog"
                  color="gray.700"
                  fontSize={{ base: 'md', md: 'lg' }}
                  htmlAst={articleBody.childMarkdownRemark.htmlAst}
                />
              </Box>
            </Box>
          </Box>
        </WidthWrapper>
      </Box>
    </PageWrapper>
  )
}

export default BlogPost

export const blogPageQuery = graphql`
  query blogPageQuery($slug: String) {
    contentfulArticle(slug: { eq: $slug }) {
      tags
      title
      publishDate(formatString: "DD MMM YYYY")
      slug
      bannerImage {
        description
        fluid(maxWidth: 1000, maxHeight: 800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      metaTitle
      metaDescription
      metaImage: bannerImage {
        fluid(maxWidth: 900, maxHeight: 450, quality: 70) {
          src
        }
      }
      articleBody {
        childMarkdownRemark {
          html
          htmlAst
        }
      }
      intro: articleBody {
        childMarkdownRemark {
          excerpt(pruneLength: 295, truncate: true)
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
