import { Alert, AlertIcon, Flex, Icon } from '@hometime-ui/core'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import React, { useState } from 'react'

const SOCIAL_MEDIA_ENUMS = Object.freeze({
  FACEBOOK: 'facebookProfile',
  TWITTER: 'twitterProfile',
  LINKEDIN: 'linkedInProfile',
})

const SocialMediaShareLinkItems = ({
  children,
  socialMediaKey,
  url,
  title,
}) => {
  switch (socialMediaKey) {
    case SOCIAL_MEDIA_ENUMS.FACEBOOK:
      return <FacebookShareButton url={url}>{children}</FacebookShareButton>
    case SOCIAL_MEDIA_ENUMS.TWITTER:
      return (
        <TwitterShareButton title={title} url={url}>
          {children}
        </TwitterShareButton>
      )
    case SOCIAL_MEDIA_ENUMS.LINKEDIN:
      return (
        <LinkedinShareButton title={title} url={url}>
          {children}
        </LinkedinShareButton>
      )
    default:
      return null
  }
}

const copyToClipBoard = (url, setCopyUrl) => {
  const copiedUrl = document.createElement('textarea')
  copiedUrl.innerText = url
  document.body.appendChild(copiedUrl)
  copiedUrl.select()
  document.execCommand('copy')
  copiedUrl.remove()
  setCopyUrl(true)

  setTimeout(() => {
    setCopyUrl(false)
  }, 2000)
}

const SocialMediaShareLinks = ({ url, title }) => {
  const [copyUrl, setCopyUrl] = useState(false)
  const links = [
    {
      key: '',
      icon: MdContentCopy,
      label: 'Copy',
    },
    {
      key: SOCIAL_MEDIA_ENUMS.FACEBOOK,
      icon: FaFacebookF,
      label: 'Facebook',
    },
    {
      key: SOCIAL_MEDIA_ENUMS.TWITTER,
      icon: FaTwitter,
      label: 'Twitter',
    },
    {
      key: SOCIAL_MEDIA_ENUMS.LINKEDIN,
      icon: FaLinkedinIn,
      label: 'Linkedin',
    },
  ]

  return (
    <Flex as="ul" listStyleType="none">
      {links.map((link, index) => {
        const isLast = index === links.length - 1

        return (
          <Flex as="li" key={link.key} mr={isLast ? 0 : 2}>
            {link.key ? (
              <SocialMediaShareLinkItems
                socialMediaKey={link.key}
                url={url}
                title={title}
              >
                <Flex
                  transition="all .15s"
                  h="10"
                  w="10"
                  _hover={{ bg: 'gray.300' }}
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                >
                  <Icon as={link.icon} w="5" h="5" color="gray.700" />
                </Flex>
              </SocialMediaShareLinkItems>
            ) : (
              <Flex
                transition="all .15s"
                h="10"
                w="10"
                _hover={{ bg: 'gray.300' }}
                borderRadius="full"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => copyToClipBoard(url, setCopyUrl)}
                pos="relative"
              >
                {copyUrl && (
                  <Alert
                    status="success"
                    pos="absolute"
                    w="auto"
                    p="2"
                    whiteSpace="nowrap"
                    top={{ base: '100%', md: 'initial' }}
                    right={{ base: 'initial', md: 'calc(100% + 12px)' }}
                    shadow="md"
                  >
                    <AlertIcon />
                    Link Copied!
                  </Alert>
                )}
                <Icon as={link.icon} w="5" h="5" color="gray.700" />
              </Flex>
            )}
          </Flex>
        )
      })}
    </Flex>
  )
}

export default SocialMediaShareLinks
